revo-grid[theme='default'] revogr-header .header-content {
    text-transform: none;
    font-weight: 600;
    color: #61656a;
    font-size: 110%;
}
revo-grid[theme='default'] revogr-header .header-rgRow.actual-rgRow {
    height: 60px;    
    background-color: #f8f9fa;
    z-index: 1;
} 
revo-grid[theme='default'] revogr-header .vertical {
    writing-mode: vertical-rl;
    text-align: left;
    padding: 5px 0px;
} 

revo-grid[theme='default'] .rgCell {
    padding-top: 4px;
    font-size: 110%;
} 